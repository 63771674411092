<template>
  <div class="bottom-nav">
    <div class="bottom-nav__item" @click="toggleTab(Tabs.HOME)">
      <div
        class="bottom-nav__icon"
        :class="{ 'is-active': activeTab === Tabs.HOME }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M9 22V12H15V22"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="bottom-nav__name">活動首頁</div>
    </div>
    <div class="bottom-nav__item" @click="toggleTab(Tabs.AWARDS)">
      <div
        class="bottom-nav__icon"
        :class="{ 'is-active': activeTab === Tabs.AWARDS }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 12V22H4V12"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M22 7H2V12H22V7Z"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 22V7"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="bottom-nav__name">獎品清單</div>
    </div>
    <div class="bottom-nav__item" @click="toggleTab(Tabs.INTRO)">
      <div
        class="bottom-nav__icon"
        :class="{ 'is-active': activeTab === Tabs.INTRO }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8L14 2Z"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14 2V8H20"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 13H8"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 17H8"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10 9H9H8"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="bottom-nav__name">活動說明</div>
    </div>
    <div class="bottom-nav__item" @click="toggleTab(Tabs.INVITE_RECORDS)">
      <div
        class="bottom-nav__icon"
        :class="{ 'is-active': activeTab === Tabs.INVITE_RECORDS }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 21V19C16 17.9391 15.5786 16.9217 14.8284 16.1716C14.0783 15.4214 13.0609 15 12 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.5 11C10.7091 11 12.5 9.20914 12.5 7C12.5 4.79086 10.7091 3 8.5 3C6.29086 3 4.5 4.79086 4.5 7C4.5 9.20914 6.29086 11 8.5 11Z"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17 11L19 13L23 9"
            stroke="#2C2C2E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div class="bottom-nav__name">邀請紀錄</div>
    </div>
  </div>
</template>

<script>
const Tabs = {
  HOME: "LiffMgmHome",
  AWARDS: "LiffMgmAwards",
  INTRO: "LiffMgmIntro",
  INVITE_RECORDS: "LiffMgmInviteRecords",
};

export default {
  data() {
    return {
      Tabs,
      activeTab: Tabs.HOME,
      tabs: [
        {
          id: "",
          text: "",
        },
      ],
    };
  },

  created() {
    this.activeTab = this.$route.name;
  },

  methods: {
    toggleTab(tab) {
      this.activeTab = tab;
      this.$router.push({ name: tab });
    },
    getIconColor(tab) {
      return this.activeTab === tab ? "var(--s-primary)" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-nav {
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 128px;
  display: flex;
  justify-content: center;
  column-gap: 20px;
  padding: 5px;
  width: 300px;
  margin: auto;

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__icon {
    padding: 6px 8px;
    margin-bottom: 4px;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      width: 40px;
      height: 40px;
      border-radius: 50px;
      opacity: 0.1;
      z-index: -1;
      background: var(--s-primary);
    }

    svg > * {
      stroke: #2c2c2e;
    }

    &.is-active {
      &::before {
        opacity: 1;
      }
      svg > * {
        stroke: #fff;
      }
    }
  }
  &__name {
    color: #2c2c2e;
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
  }
}
</style>
