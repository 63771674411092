<template>
  <div class="s-layout" :style="colorObject">
    <div class="s-container">
      <div>
        <router-view />

        <div class="bottom-wrapper">
          <bottom-nav />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BottomNav from "@/components/Page/Liff/Mgm/BottomNav.vue";
import themeColor from "@/mixins/liff/themeColor";

export default {
  mixins: [themeColor],
  components: {
    BottomNav,
  },
  computed: {
    ...mapGetters({
      themeConfig: "liffGeneral/themeConfig",
    }),
  },
};
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}

.s-layout {
  color: var(--s-gray-dark);
  background-color: var(--s-gray-lighter);
}

.content {
  height: calc(100vh - 52px - 56px);
  overflow-y: auto;
}

.bottom-wrapper {
  position: fixed;
  padding-bottom: 45px;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    178.55deg,
    rgba(242, 242, 247, 0) 1.24%,
    #f2f2f7 73.41%
  );
}
</style>
